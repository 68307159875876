var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "unordered"
    ? _c(
        "ul",
        {
          staticClass: "govuk-list",
          class: { "govuk-list--bullet": _vm.bullet }
        },
        [_vm._t("default")],
        2
      )
    : _vm.type === "unordered"
    ? _c("ol", { staticClass: "govuk-list" }, [_vm._t("default")], 2)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }